import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import {
  AboutHeroSection,
  AboutIntroSection,
  AboutAccordionSection,
  AboutTileList,
  AboutIconGridSection,
} from '../components/sections';

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="About Woosa"
        description="Good sleep is a wonderful thing. This is the reason why Woosa delivers comfortable mattresses that wouldn't burn a hole in your wallet. Contact us now."
      />
      <AboutHeroSection />
      <AboutIntroSection />
      <AboutAccordionSection />
      <AboutTileList />
      <AboutIconGridSection />
    </Layout>
  );
};

export default IndexPage;
